import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'home-outline',
    link: '/pages/dashboard',
    home: true,
  },
  {
    title: 'Client',
    icon: 'person-outline',
    link: '/pages/clients',
  },
  {
    title: 'Organisations',
    icon: 'briefcase-outline',
    link: '/pages/organisation',
  },
  {
    title: 'Auditor',
    icon: 'person-outline',
    link: '/pages/auditors',
  },
  {
    title: 'Project',
    icon: 'monitor-outline',
    link: '/pages/projects',
  },
  {
    title: 'News',
    icon: 'cast-outline',
    link: '/pages/news',
  },
  {
    title: 'Settings',
    icon: 'settings-2-outline',
    children: [
      {
        title: 'Update Password',
        icon:'lock-outline',
        link: '/pages/settings',
      },

    ],
  }
];
