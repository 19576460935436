import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NbDialogService, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { SharedvarService } from '../../../../app/sharedvar-service';
import { LoginService } from '../../../../../src/app/pages/Services/LoginService/login.service';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  isDropdownOpen = false;

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];


  public Image: string = '';
  imagename: string = '';
  public BasicDetails: any[] = [];



  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    public LoginService: LoginService,
    public router: Router,
    private cookieService: CookieService,
    private SharedvarService: SharedvarService,
    private dialogService: NbDialogService,
    private breakpointService: NbMediaBreakpointsService) {
  }

  ngOnInit() {


    if (this.LoginService.tokenExpired(this.cookieService.get('token')) || localStorage.getItem('isUserLoggedIn') == 'false') {
      this.SharedvarService.changeName("Session Expired");
      localStorage.setItem('isUserLoggedIn', "false");
      localStorage.setItem('LoginUser', '');
      localStorage.setItem('UserId', '');
      this.cookieService.delete('token');
      this.router.navigate(['/']);
      return;
    }
    this.FetchAuditorDetails();
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.raja);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }
  toggleNotifications() {
    this.router.navigate(['pages/notifications'])
  }
  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
//#region  Logout
  logout() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to log out. Are you sure you want to continue?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, log out',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked "Yes, log out"
        localStorage.setItem('isUserLoggedIn', 'false');
        localStorage.setItem('LoginUser', '');
        localStorage.setItem('UserId', '');
        this.cookieService.delete('token');
        localStorage.setItem('FilteredUserId', '');
        this.router.navigate(['/']);
      }
    });
  }
//#endregion
  createImgPath() {
    return environment.fgasApiUrl + this.imagename;
  }


//#region Fetch AuditorDetails
   public FetchAuditorDetails() {
    

    if (this.BasicDetails === undefined) {
      return;
    }
    this.LoginService
      .getOneAuditor(parseInt(localStorage.getItem('UserId')))
      .subscribe((data: any) => {
        this.BasicDetails = Object.assign(
          [],
         JSON.stringify(data)

        );
        if (data.length != 0) {
          const jsondata = data.auditors;
          this.Image = environment.fgasApiUrl + jsondata[0].Image;
          console.log("ImageName"+data[0].ImageName);
        }
      });
  }

//#endregion


  changePassword(dialog: TemplateRef<any>, size: string) {
    this.dialogService.open(dialog, {
      closeOnBackdropClick: false,
      context: {
        sizeClass: size,
      },
    });
  }
}
