import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isUserLoggedIn = localStorage.getItem('isUserLoggedIn');
    const isApiUrl = req.url.startsWith(environment.fgasApiUrl);

    if (isUserLoggedIn && isApiUrl) {
      const jwtToken = localStorage.getItem('jwtToken');

      if (jwtToken) {
        req = req.clone({
          setHeaders: { 'Authorization': `Bearer ${jwtToken}` }
        });
      }
    }

    return next.handle(req);
  }
}
