import { Component, NgZone, OnInit } from '@angular/core';
import { LoginService } from '../Services/LoginService/login.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SharedvarService } from '../../sharedvar-service';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Login } from './Login-Model';
import Swal from 'sweetalert2';

@Component({
  selector: 'ngx-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  inputType: string = 'password';
  textShow = true;
  isLoading = false;
  isUserLoggedIn = false;
  Employee: any = [];
  message: string = '';
  userid: string = '';
  password: string = '';
  disclaimer: string = "";
  subscriptions: Subscription[] = [];

  constructor(private loginservice: LoginService,
    private cookieService: CookieService,
    private router: Router,
    private zone: NgZone,
    private sharedvarservice: SharedvarService,

    private title: Title
  ) { }

  ngOnInit(): void {
    var values = localStorage.getItem('isUserLoggedIn');
    this.title.setTitle("F-GAS Login");
    if (values == 'false') {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/dashboard']);
    }
    if (localStorage.getItem('UserId') == '') {
      this.disclaimer = "";
    }
    else {
      this.sharedvarservice.name.subscribe(data => {
        this.disclaimer = data;
      });
    }
    this.loginservice.Admin;
    this.loginservice.User;
  }

  //#region Show/Hide Password
  public showPassword() {
    if (this.inputType == 'text') {
      this.inputType = 'password';
    } else {
      this.inputType = 'text';
    }
    if (document.getElementById("passwordICON").className === 'fa-solid fa-eye') {
      document.getElementById("passwordICON").className = "fa fa-eye-slash";
      document.getElementById("passwordICON").style.position = "absolute";
      document.getElementById("passwordICON").style.marginTop = "16px";
      document.getElementById("passwordICON").style.marginLeft = "252px";
      document.getElementById("passwordICON").style.cursor = "pointer";
    } else {
      document.getElementById("passwordICON").className = "fa-solid fa-eye";
      document.getElementById("passwordICON").style.position = "absolute";
      document.getElementById("passwordICON").style.marginTop = "16px";
      document.getElementById("passwordICON").style.marginLeft = "252px";
      document.getElementById("passwordICON").style.cursor = "pointer";
    }
  }

  //#endregion

  public toggleMe() {
    this.isLoading = true;
    this.textShow = false;
  }

  public hideloader() {
    this.isLoading = false;
    this.textShow = true;
  }

  //#region  Login User
  public CheckUser() {
    
    this.toggleMe();
    let loginObj = new Login();
    loginObj.userName = this.userid;
    loginObj.password = this.password;
    let subscription = this.loginservice
      .getEmployee(loginObj)
      .subscribe((data: any) => {
        if (data.isError == false) {
          
          this.hideloader();
          this.isUserLoggedIn = true;
          this.cookieService.set('token', data.token);
          console.log(data.token);
          localStorage.setItem('jwtToken', data.token);
          localStorage.setItem('LoginUser', data.firstname + ' ' + data.lastname);
          localStorage.setItem('UserId', data.employeeid);
          localStorage.setItem('isUserLoggedIn', this.isUserLoggedIn ? 'true' : 'false');
          localStorage.setItem('EmployeeId', data.employeeid);
          localStorage.setItem('EmployeeName', data.firstname + ' ' + data.lastname);
          // localStorage.setItem('DepartmentID', data.departmentid);
          localStorage.setItem('UserTypeId', data.userTypeId);
          localStorage.setItem('designationID', data.designationId)
          localStorage.setItem('CurrentSessionId', data.currentSession)
          // // this.router.navigate([{outlets:{primary:['employee']}}]);
          this.zone.run(() => {
            this.router.navigate(['/pages/dashboard']);
          });
        } else {
          this.hideloader();
          Swal.fire({
            title: 'Please Enter Valid Username and Password',
            icon: 'warning',
            confirmButtonText: 'ok',
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigateByUrl('/');
            }
          });

        }
      });
    this.subscriptions.push(subscription);
  }

  //#endregion


  onEnterKey(event: any) {
    console.log('enter');
    if (event.keyCode === 13) {
      this.CheckUser();
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((x) => {
      if (!x.closed) {
        x.unsubscribe();
      }
    });
  }

}
